import * as yup from 'yup';
import * as Sentry from '@sentry/node';
import immerProduce from 'immer';

import {
  addHours,
  addMinutes,
  getMinutes,
  setMinutes,
  startOfMinute,
  format,
} from 'date-fns';
import { SelectFieldOption } from '../components/common';
import {
  ERROR_MODIFIER_MULTIPLE_SELECTION,
  ERROR_MODIFIER_SINGLE_SELECTION,
} from '../constants/forms';
import {
  StoreMenuModifier,
  StoreMenuModifierGroup,
  StoreMenuProduct,
} from '../types/stores';
import { AddressFormData, ModifiersFormData } from '../types/forms';
import {
  LocalOrderStateModifier,
  LocalOrderStateModifierGroup,
} from '../types/orders';

/**
 * Composes next three hour select field options.
 */
export const composeNextThreeHalfHourOptions = (): SelectFieldOption<string>[] => {
  if (typeof window === 'undefined') {
    return [];
  }

  try {
    let currentTime = startOfMinute(new Date());
    const minutes = getMinutes(currentTime);

    // Skip the next half hour if it's in less than 5 minutes
    currentTime =
      minutes >= 0 && minutes <= 25
        ? setMinutes(currentTime, 30)
        : addHours(setMinutes(currentTime, 0), 1);

    const result = [
      currentTime,
      addMinutes(currentTime, 30),
      addMinutes(currentTime, 60),
    ];

    return result.map((date) => ({
      label: format(date, 'h:mm'),
      value: date.toISOString(),
    }));
  } catch (e) {
    Sentry.captureException(e);
  }

  return [];
};

/**
 * Transforms modifiers form values to local order state modifier groups with quantities
 *
 * @param modifierGroupId ID of a modifier group for which to get quantity
 * @param modifiersFormData Values from modifiers form
 */
export const getLocalOrderModifierGroup = (
  modifierGroupId: StoreMenuModifierGroup['id'],
  modifiersFormData: Omit<ModifiersFormData, 'quantity'>
): LocalOrderStateModifierGroup | null => {
  try {
    const modifierGroupFormValue = modifiersFormData[modifierGroupId];

    if (!modifierGroupFormValue) {
      return null;
    }

    const modifiers: LocalOrderStateModifier[] = [];

    switch (typeof modifierGroupFormValue) {
      case 'string': {
        // Single item selection
        modifiers.push({
          id: modifierGroupFormValue,
          quantity: 1,
        });
        break;
      }

      case 'object': {
        Object.entries(modifierGroupFormValue).forEach(
          ([modifierId, modifierValue]) => {
            const modifierQuantity = Number(modifierValue);

            if (!!modifierQuantity) {
              modifiers.push({
                id: modifierId,
                quantity: modifierQuantity,
              });
            }
          }
        );
        break;
      }

      default: {
        return null;
      }
    }

    if (modifiers.length === 0) {
      return null;
    }

    return {
      id: modifierGroupId,
      modifiers,
    };
  } catch (e) {
    // TODO: Report this error to some service
    return null;
  }
};

/**
 * Composes a product with quantities based on modifiers form data
 *
 * @param product Base product data
 * @param modifiersFormData Values from modifiers form
 */
export const composeProductWithQuantities = (
  product: StoreMenuProduct,
  modifiersFormData: Omit<ModifiersFormData, 'quantity'>
) => {
  try {
    return immerProduce<StoreMenuProduct, StoreMenuProduct>(
      product,
      (draft) => {
        const modifierGroups: StoreMenuModifierGroup[] = [];

        draft.modifierGroups.forEach(({ id: modifierGroupId }) => {
          const modifierGroup = product.modifierGroups.find(
            ({ id }) => id === modifierGroupId
          );

          if (!modifierGroup) {
            return;
          }

          const localModifierGroup = getLocalOrderModifierGroup(
            modifierGroupId,
            modifiersFormData
          );

          if (!localModifierGroup) {
            return null;
          }

          const modifiers: StoreMenuModifier[] = [];

          localModifierGroup.modifiers.forEach(
            ({ id: modifierId, quantity }) => {
              const modifierData = modifierGroup.modifiers.find(
                ({ id }) => id === modifierId
              );

              if (!modifierData) {
                return;
              }

              modifiers.push({
                ...modifierData,
                quantity,
              });
            }
          );

          modifierGroups.push({
            ...modifierGroup,
            modifiers,
          });
        });

        draft.modifierGroups = modifierGroups;
      }
    );
  } catch (e) {
    // TODO: Report this error to some service
    return null;
  }
};

/**
 * Composes a yup validation schema for given product
 *
 * @param product Product for which to compose the validation schema
 */
export const composeModifiersValidationSchema = (product: StoreMenuProduct) => {
  if (!product) {
    return null;
  }

  let validationSchema = yup.object();

  validationSchema = validationSchema.shape({
    quantity: yup.number(),
  });

  // Return the basic validation schema if there are no modifier groups
  if (!product.modifierGroups) {
    return validationSchema;
  }

  product.modifierGroups.forEach((modifierGroup) => {
    const { id, min, max } = modifierGroup;

    validationSchema = validationSchema.shape({
      [id]:
        !!min && !!max && min - max == 0
          ? yup.string().required(ERROR_MODIFIER_SINGLE_SELECTION)
          : yup
              .object()
              .test(
                'quantity_validation',
                ERROR_MODIFIER_MULTIPLE_SELECTION,
                (modifierValues) => {
                  const groupCount = Object.values(
                    modifierValues
                  ).reduce<number>((acc, curr) => {
                    const countIncrement =
                      typeof curr === 'number' ? curr : !curr ? 0 : 1;
                    return acc + countIncrement;
                  }, 0);

                  return (
                    groupCount >= (modifierGroup.min ?? 0) &&
                    groupCount <= (modifierGroup.max ?? Number.MAX_SAFE_INTEGER)
                  );
                }
              ),
    });
  });

  return validationSchema;
};

/**
 * Checks if all fields of API address structure are defined
 * @param address Address object to be checked
 */
export const isValidDeliveryAddress = (address: AddressFormData): boolean => {
  return !(
    !address?.googlePlaceId ||
    !address?.line1?.value ||
    !address?.city ||
    !address?.state?.value ||
    !address?.zip ||
    !address?.latitude ||
    !address?.longitude
  );
};
