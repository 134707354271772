import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from '@reach/utils';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import cn from 'classnames';

import { Button, GlobalContext, Icon, IconButton } from '../../common';
import { useAuth } from '../../../hooks/auth';
import MainSearch from '../main-search/MainSearch';
import { NAVIGATION_ITEMS } from '../../../constants/common';
import UserDropdown from './UserDropdown';

import styles from './Header.module.scss';

const DynamicCheckoutButton = dynamic(() => import('./CheckoutButton'), {
  ssr: false,
});

type KTownLogoProps = {
  src?: string;
  customLogo?: boolean;
};

const KTownLogo = ({
  src = '/images/ktown-logo-red.svg',
  customLogo = false,
}: KTownLogoProps) => (
  <Link href="/">
    <a className={styles.logo} aria-label="Homepage">
      <div className={styles.logoRed}>
        {customLogo ? (
          <img
            src={src}
            alt="restaurant logo"
            loading="eager"
            className="h-13"
          />
        ) : (
          <Image
            src={src}
            alt="Ktown logo"
            layout="fixed"
            loading="eager"
            height={26}
            width={124}
          />
        )}
      </div>

      <div className={styles.logoWhite}>
        <Image
          src={`/images/ktown-logo-white.svg`}
          alt="Ktown logo"
          layout="fixed"
          loading="eager"
          height={26}
          width={124}
        />
      </div>
    </a>
  </Link>
);

type MenuLinkProps = {
  href: string;
  pathname: string;
  children: string;
};

const MenuLink = memo<MenuLinkProps>(({ href, pathname, children }) => {
  const isActive = href === pathname;

  return (
    <li className={styles.navItem}>
      <Link href={href}>
        <a
          className={cn(styles.navLink, {
            [styles.active]: isActive,
          })}
        >
          {children}
        </a>
      </Link>
    </li>
  );
});

export type ExtraContent = 'hidden' | 'search' | 'navigation';

type HeaderProps = {
  logoSrc?: string;
  mainSearchIntersection?: IntersectionObserverEntry | null;
};

const Header = ({ logoSrc, mainSearchIntersection }: HeaderProps) => {
  const { openModal } = useContext(GlobalContext);

  const headerContainer = useRef<HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isTransparentVariant, setTransparentVariant] = useState<boolean>(
    false
  );
  const [extraContent, setExtraContent] = useState<ExtraContent>('hidden');

  const { isAuthenticated } = useAuth();

  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (extraContent !== 'hidden') {
      document.body.classList.add('hideOverflow');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      document.body.classList.remove('hideOverflow');
    }
  }, [extraContent]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleScroll = () => {
      setTransparentVariant(window.scrollY < 15);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!headerContainer?.current?.contains(event.target as Node)) {
        if (extraContent === 'hidden') {
          return;
        }

        setExtraContent('hidden');
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [extraContent, headerContainer]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (extraContent === 'hidden') {
        return;
      }

      if (event.key === 'Escape') {
        setExtraContent('hidden');
      }
    };

    document.addEventListener('keyup', handleEscapeKey);

    return () => {
      document.removeEventListener('keyup', handleEscapeKey);
    };
  }, [extraContent]);

  const isExtraContentVisible = extraContent !== 'hidden';

  const menuButton = (
    <IconButton
      a11yLabel={isExtraContentVisible ? 'Close Menu' : 'Open Menu'}
      icon={isExtraContentVisible ? 'cross' : 'burger'}
      iconAlt={isExtraContentVisible ? 'Cross Icon' : 'Menu Icon'}
      onClick={() =>
        setExtraContent((state) =>
          state !== 'hidden' ? 'hidden' : 'navigation'
        )
      }
    />
  );

  return (
    <>
      <Transition
        show={isExtraContentVisible}
        enter="transition-opacity ease-linear duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 left-0 z-40 w-full h-screen bg-overlay"
      />

      <header
        ref={headerContainer}
        className={cn(styles.wrapper, {
          [styles.variantHomepage]: router.pathname === '/',
          [styles.variantTransparent]: !extraContent && isTransparentVariant,
        })}
      >
        <div className="container relative">
          <div className="hidden h-16 text-sm lg:flex lg:items-center lg:justify-between">
            <div className="flex lg:flex-1">
              <div className="flex-shrink-0 flex items-center">
                {menuButton}

                <div className="block md:hidden">
                  <KTownLogo src={logoSrc} customLogo={logoSrc !== undefined} />
                </div>
                <div className="hidden md:block">
                  <KTownLogo />
                </div>
              </div>
            </div>

            <Transition
              show={
                extraContent !== 'search' &&
                !mainSearchIntersection?.isIntersecting
              }
              enter="transition-opacity ease-linear duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button
                type="button"
                className={styles.searchShortcut}
                onClick={() =>
                  setExtraContent((state) => {
                    if (state === 'navigation') {
                      setTimeout(() => setExtraContent('search'), 250);
                      return 'hidden';
                    }

                    return 'search';
                  })
                }
              >
                <span>Search</span>

                <div className={styles.searchShortcutButton}>
                  <Icon icon="search-white" alt="Magnifying glass icon" />
                </div>
              </button>
            </Transition>

            <div className="flex lg:flex-1 items-center justify-end">
              <div className="mr-10">
                <DynamicCheckoutButton
                  headerExtraContent={extraContent}
                  hideHeaderExtraContent={() => setExtraContent('hidden')}
                />
              </div>

              {isAuthenticated ? (
                <UserDropdown
                  headerExtraContent={extraContent}
                  hideHeaderExtraContent={() => setExtraContent('hidden')}
                />
              ) : (
                <Link href="/sign-in" passHref>
                  <Button as="a" filled={false} size="small">
                    Sign in
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between h-16 py-2 lg:hidden">
            {menuButton}

            <div className="block md:hidden">
              <KTownLogo src={logoSrc} customLogo={logoSrc !== undefined} />
            </div>
            <div className="hidden md:block">
              <KTownLogo />
            </div>

            <div className="w-6 lg:hidden" />
          </div>
        </div>

        <Transition
          show={isExtraContentVisible && extraContent === 'search'}
          enter="transition-opacity ease-linear duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={false}
        >
          <div className="absolute inset-x-0 shadow-bottom-lg border-t border-gray">
            <div className="inset-0 bg-white">
              <div className="container h-screen lg:h-auto">
                <div className="py-10">
                  <MainSearch
                    inputRef={inputRef}
                    onSubmit={() => setExtraContent('hidden')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Transition>

        <Transition
          show={isExtraContentVisible && extraContent === 'navigation'}
          enter="transition-opacity ease-linear duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-x-0 shadow-bottom-lg border-t border-gray">
            <div className="inset-0 bg-white">
              <div className="container h-screen lg:h-auto">
                <nav className={styles.navigation}>
                  <ul className={styles.navList}>
                    {NAVIGATION_ITEMS.map(({ label, href }, key) => (
                      <MenuLink
                        key={key}
                        href={href}
                        pathname={router.pathname}
                      >
                        {label}
                      </MenuLink>
                    ))}
                  </ul>

                  <div className="flex flex-col items-end">
                    <div className="flex flex-col items-center lg:flex-row">
                      <p className="mb-5 font-medium text-brown-light lg:mb-0 lg:mr-5">
                        Are you a restaurant?
                      </p>
                      <Button
                        size="small"
                        onClick={() => {
                          openModal('join_us');
                          setExtraContent('hidden');
                        }}
                      >
                        Join us!
                      </Button>
                    </div>

                    <div className="hidden lg:flex lg:items-center lg:mt-14">
                      <Icon
                        icon="twitter"
                        alt="Twitter icon"
                        className="mr-5"
                      />
                      <Icon
                        icon="facebook"
                        alt="Facebook icon"
                        className="mr-5"
                      />
                      <Icon icon="linkedin" alt="LinkedIn icon" />
                    </div>
                  </div>

                  <div className={styles.illustration}>
                    <Image
                      src="/images/illustration-dessert.svg"
                      alt="Dessert illustration"
                      width={112}
                      height={139}
                      loading="eager"
                      layout="fixed"
                    />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </Transition>
      </header>
    </>
  );
};

export default Header;
