import * as yup from 'yup';
import { Store } from '../types/stores';
import { LocalOrderState } from '../types/orders';
import { UserProfile } from '../types/auth';
import {
  areCoordinatesInDeliveryArea,
  extractAddressByGooglePlaceId,
} from '../helpers/orders';

export const US_PHONE_REGEX = /^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/;
export const US_ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;

// Messages
export const ERROR_FIELD_REQUIRED = 'This field is required';
export const ERROR_INVALID_EMAIL =
  'E-mail Invalid - Enter format name@example.com';
export const ERROR_INVALID_PHONE_NUMBER =
  'Number Invalid - Enter format 555-555-5555';
export const ERROR_ADDRESS_TOO_GENERIC =
  'Please enter a fully specified address';
export const ERROR_MODIFIER_SINGLE_SELECTION =
  'Please select exactly one of the options above';
export const ERROR_MODIFIER_MULTIPLE_SELECTION =
  'Please select a valid combination of the options options above';
export const ERROR_INVALID_ZIP_CODE =
  'Invalid zip code. Please use correct format (e.g. 10001)';
export const ERROR_PAYMENT_CVV2_LENGTH =
  'CVV2 code must be a three-or-four digit number';

export const SUCCESS_FORM_SUBMIT = 'The form has been sent successfully';

export const SELECT_STATE_OPTIONS = [
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'FM',
    value: 'FM',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MH',
    value: 'MH',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PW',
    value: 'PW',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WY',
    value: 'WY',
  },
];

// Validation
const REQUIRED_STRING_VALIDATION = yup.string().required(ERROR_FIELD_REQUIRED);

const EMAIL_VALIDATION = yup.string().email(ERROR_INVALID_EMAIL);

const PHONE_NUMBER_VALIDATION = yup
  .string()
  .matches(US_PHONE_REGEX, ERROR_INVALID_PHONE_NUMBER);

const SELECT_VALUE_VALIDATION = yup.object({
  label: REQUIRED_STRING_VALIDATION,
  // Not ideal, but yup doesn't support type unions
  value: yup.lazy((value) =>
    typeof value === 'number'
      ? yup.number().required(ERROR_FIELD_REQUIRED)
      : yup.string().required(ERROR_FIELD_REQUIRED)
  ),
});

export const SELECT_VALUE_STRING_VALIDATION = yup.object({
  label: REQUIRED_STRING_VALIDATION,
  value: REQUIRED_STRING_VALIDATION,
});

export const ADDRESS_VALIDATION_SCHEMA = yup.object({
  googlePlaceId: yup
    .string()
    .required(ERROR_FIELD_REQUIRED)
    .test('delivery_area', 'Not in delivery Area', (address, testContext) => {
      // Return "true" in case any of the required context data is undefined (form might be used outside of checkout)
      if (!testContext?.options?.context?.store) {
        return true;
      }

      const store = testContext.options.context.store as Store;

      const lat = testContext.parent?.latitude;
      const lng = testContext.parent?.longitude;

      if (!lat || !lng || !store) {
        return false;
      }

      return areCoordinatesInDeliveryArea(lat, lng, store);
    }),
  line1: SELECT_VALUE_STRING_VALIDATION.required(ERROR_FIELD_REQUIRED),
  line2: yup.string().notRequired(),
  city: yup.string().required(ERROR_FIELD_REQUIRED),
  state: SELECT_VALUE_STRING_VALIDATION.required(ERROR_FIELD_REQUIRED),
  zip: yup
    .string()
    .required(ERROR_FIELD_REQUIRED)
    .matches(US_ZIP_REGEX, ERROR_INVALID_ZIP_CODE),
  latitude: yup.number().required(ERROR_FIELD_REQUIRED),
  longitude: yup.number().required(ERROR_FIELD_REQUIRED),
});

export const PAYMENT_VALIDATION_SCHEMA = yup.object({
  cvv2: yup
    .string()
    .min(3, ERROR_PAYMENT_CVV2_LENGTH)
    .max(4, ERROR_PAYMENT_CVV2_LENGTH)
    .required(ERROR_FIELD_REQUIRED),
  expiration: yup.string().required(ERROR_FIELD_REQUIRED),
  name: yup.string().required(ERROR_FIELD_REQUIRED),
  token: yup.string().required(ERROR_FIELD_REQUIRED),
  zip: yup
    .string()
    .required(ERROR_FIELD_REQUIRED)
    .matches(US_ZIP_REGEX, ERROR_INVALID_ZIP_CODE),
});

export const SIGN_IN_FORM_VALIDATION_SCHEMA = yup.object({
  email: EMAIL_VALIDATION.required(ERROR_FIELD_REQUIRED),
});

export const SIGN_IN_VERIFICATION_CODE_VALIDATION_SCHEMA = yup.object({
  code: REQUIRED_STRING_VALIDATION,
});

export const CONTACT_US_FORM_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required(ERROR_FIELD_REQUIRED),
  emailAddress: EMAIL_VALIDATION.required(ERROR_FIELD_REQUIRED),
  message: yup.string().required(ERROR_FIELD_REQUIRED),
});

export const JOIN_US_FORM_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required(ERROR_FIELD_REQUIRED),
  emailAddress: EMAIL_VALIDATION.required(ERROR_FIELD_REQUIRED),
  phoneNumber: PHONE_NUMBER_VALIDATION.required(ERROR_FIELD_REQUIRED),
  restaurantName: yup.string().required(ERROR_FIELD_REQUIRED),
  restaurantAddress: yup.string().required(ERROR_FIELD_REQUIRED),
});

export const MAIN_SEARCH_FORM_VALIDATION_SCHEMA = yup.object({
  search: yup.string().required(ERROR_FIELD_REQUIRED),
  datetime: SELECT_VALUE_VALIDATION,
  type: SELECT_VALUE_STRING_VALIDATION,
});

export const ORDER_SCHEDULE_FORM_VALIDATION_SCHEMA = yup.object({
  date: yup.object({
    index: yup.number().required(ERROR_FIELD_REQUIRED),
    value: yup.string().required(ERROR_FIELD_REQUIRED),
  }),
  time: SELECT_VALUE_STRING_VALIDATION,
});

export const PROFILE_PERSONAL_FORM_VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required(ERROR_FIELD_REQUIRED),
  surname: yup.string().required(ERROR_FIELD_REQUIRED),
  //emailAddress: EMAIL_VALIDATION,
  phoneNumber: PHONE_NUMBER_VALIDATION.required(ERROR_FIELD_REQUIRED),
  address: ADDRESS_VALIDATION_SCHEMA,
});

export const CHECKOUT_FORM_COMMON_VALIDATION_SCHEMA = yup.object({
  customTipAmount: yup.number().required(ERROR_FIELD_REQUIRED),
  goGreen: yup.boolean().required(ERROR_FIELD_REQUIRED),
  note: yup.string().notRequired(),
  payment: yup.object().when('paymentSelect', {
    is: (option) => option?.value === 'new',
    then: PAYMENT_VALIDATION_SCHEMA,
  }),
  paymentSave: yup.boolean(),
  paymentSelect: SELECT_VALUE_STRING_VALIDATION.required(ERROR_FIELD_REQUIRED),
  tip: yup.string().nullable().notRequired(),
});

export const CHECKOUT_FORM_VALIDATION_SCHEMA = CHECKOUT_FORM_COMMON_VALIDATION_SCHEMA.when(
  '$orderType',
  {
    is: 'delivery',
    then: (schema) =>
      schema.shape({
        address: yup.object().when('addressSelect', {
          is: (option) => option?.value === 'new',
          then: ADDRESS_VALIDATION_SCHEMA,
        }),
        addressSelect: SELECT_VALUE_STRING_VALIDATION.required(
          ERROR_FIELD_REQUIRED
        ).test(
          'delivery_area',
          'Not in delivery Area',
          (selectedOption, testContext) => {
            // If "new address" option is selected, leave the validation on the address schema
            if (selectedOption.value === 'new') {
              return true;
            }

            // Return "false" in case any of the required context data is undefined
            if (
              !testContext?.options?.context?.store ||
              !testContext?.options?.context?.orderState
            ) {
              return false;
            }

            const store = testContext.options.context.store as Store;
            const orderState = testContext.options.context
              .orderState as LocalOrderState;
            const userProfile = testContext.options.context
              .userProfile as UserProfile;

            const selectedAddress = extractAddressByGooglePlaceId(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              selectedOption.value!,
              orderState,
              userProfile
            );

            if (!selectedAddress) {
              return false;
            }

            return areCoordinatesInDeliveryArea(
              selectedAddress.latitude,
              selectedAddress.longitude,
              store
            );
          }
        ),
      }),
  }
);

export const MOBILE_CART_FORM_VALIDATION_SCHEMA = yup.object({
  goGreen: yup.boolean().required(ERROR_FIELD_REQUIRED),
});
