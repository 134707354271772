import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { MutableRefObject, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUID } from 'react-uid';

import { composeNextThreeHalfHourOptions } from '../../../helpers/forms';
import {
  Button,
  IconButton,
  SelectField,
  SelectFieldOption,
} from '../../common';
import Separator from '../../common/separator/Separator';
import { MAIN_SEARCH_FORM_VALIDATION_SCHEMA } from '../../../constants/forms';
import { MainSearchFormData } from '../../../types/forms';
import { SEARCH_TYPE_OPTIONS } from '../../../constants/common';

import styles from './MainSearch.module.scss';

type MainSearchProps = {
  kind?: 'default' | 'homepage';
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  onSubmit?: () => void;
};

const MainSearch = ({
  kind = 'default',
  inputRef,
  onSubmit: onSubmitCallback,
}: MainSearchProps) => {
  const router = useRouter();

  const searchInputId = useUID();

  const datetimeValues = useMemo<SelectFieldOption[]>(() => {
    return [
      {
        label: 'ASAP',
        value: 'ASAP',
      },
      ...composeNextThreeHalfHourOptions(),
    ];
  }, []);

  const { control, handleSubmit, register } = useForm<MainSearchFormData>({
    resolver: yupResolver(MAIN_SEARCH_FORM_VALIDATION_SCHEMA),
    defaultValues: {
      search: '',
      datetime: datetimeValues[0],
      type: SEARCH_TYPE_OPTIONS[0],
    },
  });

  const onSubmit = handleSubmit(async ({ datetime, search, type }) => {
    await router.push({
      pathname: '/restaurants',
      query: {
        search: search,
        datetime: datetime.value,
        // searchBy: searchBy.value,
        type: type.value,
      },
    });

    if (typeof onSubmitCallback === 'function') {
      onSubmitCallback();
    }
  });

  const { ref: searchRef, ...searchRest } = register('search');

  return (
    <form onSubmit={onSubmit}>
      <div className={cn(styles.base, styles[kind])}>
        {/* {kind === 'homepage' && (
          <SelectFieldForm
            control={control}
            error-message={errors?.searchBy}
            name="searchBy"
            label="Search by"
            kind="transparent"
            defaultValue={{ label: 'Location', value: 'location' }}
            className="w-full px-4 lg:w-40 lg:px-0 lg:mx-8"
            options={[
              {
                label: 'Location',
                value: 'location',
              },
              {
                label: 'Restaurants',
                value: 'restaurants',
              },
            ]}
          />
        )} */}

        <div className={styles.main}>
          <div className={styles.textInputWrapper}>
            <label htmlFor={searchInputId} className={styles.textInputLabel}>
              Search
            </label>

            <input
              {...searchRest}
              type="text"
              id={searchInputId}
              ref={(e) => {
                searchRef(e);
                if (inputRef) {
                  inputRef.current = e;
                }
              }}
              placeholder="Try... Kimchi jjigae"
              className={styles.textInput}
            />
          </div>

          {/*          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <SelectField
                {...field}
                ref={inputRef}
                onInputChange={(query, { action }) => {
                  // Create a "dummy" option for text input
                  if (action === 'input-change') {
                    field.onChange({
                      label: query,
                      value: query,
                    });
                  }
                }}
                options={[
                  {
                    label: 'BBQ',
                    value: 'BBQ',
                  },
                  {
                    label: 'Buldak',
                    value: 'Buldak',
                  },
                ]}
                label="Search"
                kind="transparent"
                placeholder="Try... Kimchi jjigae"
                className="w-full px-4 lg:px-0"
                tabSelectsValue={false}
                hideChevron
              />
            )}
          />*/}

          <Separator
            type="horizontal"
            className="lg:hidden w-11/12 mt-0 mb-0"
          />
          <Separator type="vertical" className="hidden lg:block py-4" />

          <Controller
            name="datetime"
            control={control}
            render={({ field }) => (
              <>
                <SelectField
                  {...field}
                  label="Date &amp; Time"
                  kind="transparent"
                  className="w-full mt-1 lg:w-32 px-4 lg:px-0"
                  options={datetimeValues}
                />
              </>
            )}
          />

          <Separator
            type="horizontal"
            className="lg:hidden w-11/12 mt-0 mb-0"
          />
          <Separator type="vertical" className="hidden lg:block py-4" />

          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                <SelectField
                  {...field}
                  label="Type"
                  kind="transparent"
                  className="w-full mt-1 lg:w-40 px-4 lg:px-0"
                  options={SEARCH_TYPE_OPTIONS}
                />
              </>
            )}
          />

          {/* {kind === 'default' && (
            <>
              <Separator
                type="horizontal"
                className="lg:hidden w-11/12 mt-0 mb-0"
              />
              <Separator type="vertical" className="hidden lg:block py-4" />

              <SelectFieldForm
                control={control}
                error-message={errors?.searchBy}
                name="searchBy"
                label="Search by"
                kind="transparent"
                defaultValue={{ label: 'Location', value: 'location' }}
                className="w-full mt-1 lg:w-32 px-4 lg:px-0"
                options={[
                  {
                    label: 'Location',
                    value: 'location',
                  },
                  {
                    label: 'Restaurants',
                    value: 'restaurants',
                  },
                ]}
              />
            </>
          )} */}

          <IconButton
            type="submit"
            a11yLabel="Search"
            icon="search-white"
            iconAlt="Magnifying glass icon"
            className={styles.buttonDesktop}
          />
        </div>

        {kind === 'homepage' && (
          <img
            src={'/images/illustration-sticks.svg'}
            alt="Dessert illustration"
            className="hidden lg:block lg:absolute lg:-left-12 lg:-top-20 xl:-left-20"
          />
        )}
      </div>

      <Button type="submit" className="w-full mx-auto max-w-sm mt-5 lg:hidden">
        Find
      </Button>
    </form>
  );
};

export default MainSearch;
