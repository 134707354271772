import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';

import { useAuth } from '../../../hooks/auth';
import {
  HeaderUserLoader,
  Icon,
  NextLink,
  TransparentButton,
} from '../../common';
import { ExtraContent } from './Header';

import styles from './Header.module.scss';

type UserDropdownProps = {
  headerExtraContent: ExtraContent;
  hideHeaderExtraContent: () => void;
};

const UserDropdown: React.FC<UserDropdownProps> = ({
  headerExtraContent,
  hideHeaderExtraContent,
}) => {
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (referenceElement?.contains(event.target as Node)) {
        return;
      }

      if (!popperElement?.contains(event.target as Node)) {
        if (!isDropdownOpen) {
          return;
        }

        setDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [isDropdownOpen, referenceElement, popperElement]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (!isDropdownOpen) {
        return;
      }

      if (event.key === 'Escape') {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('keyup', handleEscapeKey);

    return () => {
      document.removeEventListener('keyup', handleEscapeKey);
    };
  }, [isDropdownOpen]);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [24, 8],
          },
        },
      ],
    }
  );

  const { userProfile, signOut } = useAuth();

  const router = useRouter();

  useEffect(() => {
    if (headerExtraContent !== 'hidden') {
      setDropdownOpen(false);
    }
  }, [headerExtraContent]);

  if (!userProfile) {
    return <HeaderUserLoader />;
  }

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        className={styles.userDropdownButton}
        onClick={() => {
          hideHeaderExtraContent();
          setDropdownOpen((state) => !state);
        }}
      >
        <img
          src={userProfile.image.url}
          alt="Your profile picture"
          className="w-9 h-9 rounded-full object-cover"
        />

        <Icon
          icon={isDropdownOpen ? 'chevron-down-active' : 'chevron-down'}
          alt="Arrow down icon"
          className="ml-2"
        />

        <span className="sr-only">User dropdown navigation</span>
      </button>

      <Transition
        show={isDropdownOpen}
        enter="transition-opacity ease-linear duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          className={styles.userDropdownPopper}
          {...popperAttributes.popper}
        >
          <ul>
            <li className="pb-3 border-b border-gray-dark">
              <NextLink
                href="/profile/personal"
                kind={
                  router.pathname === '/profile/personal'
                    ? 'primary'
                    : 'secondary'
                }
                className={
                  router.pathname === '/profile/personal'
                    ? 'text-brown'
                    : undefined
                }
              >
                Your profile
              </NextLink>
            </li>

            <li className="pt-3">
              <TransparentButton
                kind="secondary"
                onClick={() => signOut()}
                className={styles.signOutButton}
              >
                Sign out
              </TransparentButton>
            </li>
          </ul>
          <div className={styles.userDropdownArrow} />
        </div>
      </Transition>
    </>
  );
};

export default UserDropdown;
